import { getLocation } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { useTypedSelector } from '../../../../hooks';
import { actions } from '../../ducks';
import { ItemAddHead, PS_SEARCH_ID } from './ItemAddHead';
import { ItemConfig } from './ItemConfig';
import { Modal } from './Modal';
import { SearchList } from './SearchList';
import { r } from '../../../../routes';

export const ItemAdd: React.FC = () => {
  const history = useHistory();
  const { pathname } = useTypedSelector(getLocation);
  const autoFocus = pathname === r.generalFlow.items.add();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (autoFocus) {
      const psSearchInput = document.getElementById(PS_SEARCH_ID);
      psSearchInput?.focus();
    }
  }, [pathname]);

  const cancelAddItem = (): void => {
    // We go back in history because adding/editing should use 1 push and then replace. Going back makes us end up at /items, keeps the history logical when moving back/forward
    history.goBack();
    dispatch(actions.setQueryPs(null));
  };

  return (
    <Modal isActive={true} closeHandler={cancelAddItem} closeByBackdrop={false}>
      <ItemAddHead backHandler={history.goBack} closeHandler={cancelAddItem} autoFocus={autoFocus} />
      <Route path={r.generalFlow.items.add()} exact={true} component={SearchList} />
      <Route path={r.generalFlow.items.configure()} component={ItemConfig} />
    </Modal>
  );
};
