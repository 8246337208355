//TODO enable eslint name convention
import {
  Address,
  GeoAutocompleteAddress,
  InstructionSuspicion,
  ItemCreationParams,
  ProductSelectionGroup,
  Quote,
} from '@brenger/api-client';
import { Nullable, StopType } from '../../typings';
import { TransformedLot } from '../../utils/tbAuctionsMethods';
import { PSFormData } from './components/ProductSelection/ItemForm';
import {
  ContactCustomer,
  ContactStop,
  GfChoices,
  PSPrefillProductAction,
  PsPrefillOtherAction,
  QuoteRejectedReason,
  SetDestinationMetaAction,
  SetLocalityChangePayload,
  SetPrefillPsAction,
  SetSearchResultPsAction,
  TransportRequestValidationError,
} from './typings';

// INTERFACES & PROP TYPES

export interface FloorData {
  floor?: number;
  isCustom: boolean;
  floorString: string;
  hasElevator: boolean;
}

export interface Addition {
  beforeText?: string;
  afterText?: string;
  text?: string;
  transKey?: string;
  price: number | null;
}

export enum HomeSituations {
  OUTSIDE = 'outside',
  INSIDE = 'inside',
  GROUND_FLOOR_AND_READY = 'ground_floor_and_ready',
  GROUND_FLOOR_NOT_READY = 'ground_floor_not_ready',
  FLOOR_WITH_ELEVATOR = 'floor_with_elevator',
  FLOOR_WITHOUT_ELEVATOR = 'floor_without_elevator',
  BASEMENT_WITHOUT_ELEVATOR = 'basement_without_elevator',
}

export enum ItemFields {
  TITLE = 'title',
  WIDTH = 'width',
  HEIGHT = 'height',
  LENGTH = 'length',
  COUNT = 'count',
  WEIGHT = 'weight',
  MORE_INFO = 'more_info',
  EXTRA_CARE_MATCH = 'extra_care.match',
  EXTRA_CARE_SELECTION = 'extra_care.selection',
  EXTRA_CARE_INSURED = 'extra_care.insured',
  HEAVY = 'heavy',
  FRAGILE = 'fragile',
  PRODUCT_SELECTION_GROUP = 'product_selection.product_group',
  PRODUCT_SELECTION_GROUPS_TO_VERIFY = 'product_selection.product_groups_to_verify',
}

export enum User {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  EMAIL = 'email',
  PHONE = 'phone',
}

export interface GroupAddressOption {
  label: string;
  options: Address[];
}

export interface GroupAddress {
  [StopType.PICKUP]: GroupAddressOption;
  [StopType.DELIVERY]: GroupAddressOption;
}

export enum SubStepFieldNamesPickup {
  INVOICE = 'pickup.invoice',
  OTHER_SITUATION_NOTE = 'pickup.other_situation_note',
  WEIGHT_TAILGATE = 'pickup.weight_tailgate',
  WEIGHT_ELEVATOR = 'pickup.weight_elevator',
  SITUATION_HOME = 'pickup.home_situation',
  AUCTION_TYPE = 'pickup.auction_type',
  SITUATION_HOME_FLOORS_COMBINED = 'pickup.home_floor_situation_combined',
  DIFFERENT_DELIVERY_DATE = 'pickup.different_delivery_date',
  TIME = 'pickup.time',
  TIME_START_CUSTOM = 'pickup.time_start_custom',
  TIME_END_CUSTOM = 'pickup.time_end_custom',
}

export enum FieldNamesDate {
  PICKUP = 'pickup_date',
  DELIVERY = 'delivery_date',
  DIFFERENT_DELIVERY_DATE = 'different_delivery_date',
}

export enum FieldNamesBookAVan {
  IMAGES = 'images',
  DESCRIPTION = 'description',
}

export enum FieldNamesHomeSituation {
  TYPE = 'home_situation_type',
}

export enum FieldNamesProductPayment {
  OPT_IN = 'opt_in',
  AMOUNT = 'amount',
}

export enum WeightFields {
  WEIGHT = 'weight',
}

export enum AuctionTypes {
  BELGA = 'belgaveilingen',
  TROOSTWIJK = 'troostwijk',
  ONLINEVEILINGMEESTERS = 'onlineveilingmeester',
  VAVATO = 'vavato',
  LOT_TISSIMO = 'Lot-Tissimo',
  JUSTIZ_AUKTION = 'Justiz Auktion',
  BONANZA = 'bonanza',
  EBID = 'ebid',
  OTHERS = 'others',
}

export enum Company {
  NAME = 'name',
  LINE1 = 'line1',
  COUNTRY = 'country',
  POSTAL_CODE = 'postal_code',
  LOCALITY = 'locality',
  COC_NUMBER = 'coc_number',
  VAT_NUMBER = 'vat_number',
}

export enum SubStepFieldNamesDelivery {
  AUCTION_TYPE = 'delivery.auction_type',
  SITUATION = 'delivery.situation',
  OTHER_SITUATION_NOTE = 'delivery.other_situation_note',
  WEIGHT_TAILGATE = 'delivery.weight_tailgate',
  WEIGHT_ELEVATOR = 'delivery.weight_elevator',
  SITUATION_HOME = 'delivery.home_situation',
  SITUATION_HOME_FLOORS_COMBINED = 'delivery.home_floor_situation_combined',
  FLOOR_COUNT = 'delivery.floor_count',
  FLOOR_CUSTOM = 'delivery.floor_count_custom',
  FLOOR_ELEVATOR = 'delivery.floor_elevator',
  TIME = 'delivery.time',
  SET_PICKUP_TO_DELIVERY_TIME = 'setPickupToDeliveryTime',
}

export enum GeneralFlowForms {
  PICKUP = '@@GeneralFlow/pickup_order_form',
  ITEMS = '@@GeneralFlow/items_order_form',
  DELIVERY = '@@GeneralFlow/delivery_order_form',
  DATE = '@@GeneralFlow/date_form',
  TIME = '@@GeneralFlow/time_form',
  TERMS = '@@GeneralFlow/conditions_order_form',
  BOOK_A_VAN = '@@GeneralFlow/book_a_van',
  PRODUCT_PAYMENT = '@@GeneralFlow/product_payment',
  PAYMENT = 'paymentForm',
}

export enum AccountTypeFields {
  TYPE = 'customer.type',
}

export enum PriceListTypes {
  STOP_SITUATION_AUCTION = 'stop_situation.auction',
  STOP_SITUATION_HOME = 'stop_situation.home',
  STOP_SITUATION_STORE = 'stop_situation.store',
  CUSTOM_TIMESLOT_FIRST = 'custom_timeslot.first',
  CUSTOM_TIMESLOT_SECOND = 'custom_timeslot.second',
  CARRYING_HELP_EQUIPMENT_TAILGATE = 'carrying_help.equipment_tailgate',
  CARRYING_HELP_EQUIPMENT_TAILGATE_EXTRA_DRIVER = 'carrying_help.tailgate_extra_driver',
  CARRYING_HELP_EQUIPMENT_TAILGATE_PALLET_JACK = 'carrying_help.equipment_tailgate_pallet_jack',
  CARRYING_HELP_EXTRA_DRIVER = 'carrying_help.extra_driver',
  PER_FLOOR_WITHOUT_EXTRA_HELP = 'per_floor.without_extra_help',
  PER_FLOOR_WITH_EXTRA_HELP = 'per_floor.with_extra_help',
  PER_BASEMENT_WITHOUT_EXTRA_HELP = 'per_basement.without_extra_help',
  PER_BASEMENT_WITH_EXTRA_HELP = 'per_basement.with_extra_help',
}

export type TitleStepName =
  | 'timeCustomPickup'
  | 'itemSet'
  | 'date_pickup'
  | 'date_delivery'
  | 'date_pickup_longDistance'
  | 'date_delivery_longDistance'
  | 'timePickup'
  | 'timeDelivery'
  | 'timeCustomPickup'
  | 'timeCustomDelivery'
  | 'helpPickup'
  | 'helpDelivery'
  | 'contactPickup'
  | 'contactDelivery'
  | 'weight';
interface Notifications {
  message: string;
  type: string;
  visible: boolean;
}
interface Layout {
  sheet: boolean;
  step: number;
  modal_time: boolean;
  modal_items: boolean;
  modal_flex_dates: boolean;
  step_loading: boolean;
}

interface Price {
  amount: number;
  isLoading: boolean;
  retryAttempt: number;
  failedAttempts: boolean;
  rejected: QuoteRejectedReason | null;
}

export interface ItemWithPSAndLot extends ItemCreationParams {
  /**
   * Save product selection data temporary
   * - So we always now how we came to this item
   * - So we can pass on values later to other services
   * - Hard code the index for convenience, so we don't have to pass it around
   * Same goes for auction data
   */
  ps_temp: {
    /**
     * Whatever the user has selected
     */
    group: ProductSelectionGroup | null;
    /**
     * How we did we arrive the values?
     */
    isManual: boolean;
    /**
     * Uuid from the item in the item array, gets generated upon adding the item
     */
    uuid?: string;
    /**
     * Save the chosen props
     */
    properties: Partial<PSFormData['ps_temp']['properties']>;
    /**
     * Full title of the item added, materials included
     */
    fullTitle: string;
    /**
     * Origin: how did this item land in itemsets? For reference and to decide if further action is needed
     */
    origin?: PsPrefillOtherAction | PSPrefillProductAction;
    /**
     * Did the user opened up the edit screen of this product
     */
    reviewed?: boolean;
  };
  lot_temp?: TransformedLot;
}

export type ExpectedUtmParams = 'utm_source';

export interface AbTest {
  id: number;
  v: number;
}

export interface GeneralTransport {
  cacheUntil?: number;
  request: {
    uuid: string | null;
  };
  destination: {
    [StopType.PICKUP]: Nullable<GeoAutocompleteAddress>;
    pickup_meta: SetDestinationMetaAction['payload'];
    [StopType.DELIVERY]: Nullable<GeoAutocompleteAddress>;
    delivery_meta: SetDestinationMetaAction['payload'];
  };
  error: string | null;
  price: Price;
  priceStructure: Quote['price_structure'];
  priceList: Nullable<PriceListTypes>;
  notifications: Notifications;
  layout: Layout;
  referrer: string;
  ab?: AbTest[];
  isSubmitting: boolean;
  quote: Nullable<Quote>;
  historicalAddresses: Address[];
  lastVisitTimes: string[];
  localityChange: SetLocalityChangePayload | null;
  instructionSuspicion: {
    pickup: InstructionSuspicion[];
    delivery: InstructionSuspicion[];
  };
  vat_number: {
    loading: boolean;
    value: string;
  };
  tr_validation_error: TransportRequestValidationError | null;
  product_selection: {
    query: string | null;
    items: ItemWithPSAndLot[];
    searchResult: SetSearchResultPsAction['payload'];
    prefill: SetPrefillPsAction['payload'];
  };
  contact: {
    customer: null | ContactCustomer;
    pickup: null | ContactStop;
    delivery: null | ContactStop;
  };
  utm?: {
    // Date string
    ttl: string;
    // Actual values
    values: null | { [key in ExpectedUtmParams]?: string };
  };
  choices?: GfChoices;
}
